/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  height: 60px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

thead[class*="ant-table-thead"] th {
    background-color: #181313 !important;
    color: white;
}

.ant-pagination-item-active, .ant-pagination-item-active > a {
    background-color: #181313 !important;
    color: white !important;
    border-color: #181313;
}

.ant-pagination-item:hover{
    background-color: #1890ff !important;
    color: white;
    border-color: #1890ff;
}


.ant-page-header-heading-title {
    font-size: xx-large;
}

.ant-table-row:hover {
    color: #0f0f10;
}
